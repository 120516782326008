<template>
  <b-container class="mt-sm-5 mt-3">
    <b-row class="a-section-title">
      <b-col>
        <h2 class="text-center">
          <span class="w-color-secondary z-fancy-underline">
            {{ $t("Naše najpopulárnejšie") }}<svg-fancy-underline-icon />
            <img src="/topCourses.png" class="a-small-logo" alt="" />
          </span>
          <span class="w-color-black"> {{ $t("video kurzy") }}</span>
        </h2>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <Loadable :items="courses" />
      <template v-if="courses && courses.length">
        <b-col
          v-for="(course, i) in courses"
          :key="i"
          lg="4"
          md="6"
          sm="12"
          class="top-courses-container d-flex justify-content-center"
        >
          <CourseCard v-if="course._model === 'course'" :course="course" />
          <PathCard v-else :path="course" />
        </b-col>
      </template>
    </b-row>
    <b-row>
      <b-col class="text-center">
        <router-link to="/kurzy">
          <b-button variant="primary" class="a-poppins-btn -medium -wide">{{
            $t("Pozrieť všetky kurzy")
          }}</b-button>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  components: {
    "svg-fancy-underline-icon": () =>
      import("/assets/icons/fancyUnderline.svg?inline"),
    PathCard: () => import("/components//Path/PathCard.vue"),
    CourseCard: () => import("/components/CourseCard.vue"),
    Loadable: () => import("/components/Loadable.vue"),
  },
  props: {
    courses: {
      type: Array,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.top-courses-container {
  &:nth-child(7),
  &:nth-child(8) {
    @media only screen and (max-width: 1399px) {
      display: none !important;
    }
  }

  @media only screen and (min-width: 1400px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
</style>
